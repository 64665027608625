import React from 'react'
import { connect } from 'react-redux'

import { selectLabel } from '../../actions/explore'
import styles from './LabelList.module.css'

const LabelList = ({dataset, labels, selectLabel}) => {
  const onSelectLabel = (label) => () => {
    selectLabel(label)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
  return (
    <div className={styles.labelList}>
      <p className="text-center">
        <button className="btn btn-link" onClick={() => selectLabel(null)}>All classes with noise for {dataset}</button>
      </p>
      <div className={styles.labels}>
        {labels.map((label) => {
          return (
            <button key={label} className="btn btn-link" onClick={onSelectLabel(label)}>{label}</button>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const metadata = state.explore.metadata[state.explore.dataset]

  return {
    dataset: state.explore.dataset,
    labels: metadata ? metadata.classes : []
  }
}

const mapDispatchToProps = {
  selectLabel
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelList)
