import { SELECT_DATASET, SELECT_LABEL, LOAD_METADATA, LOAD_DATA } from './types'
import * as api from '../api'

export const selectDataset = (name) => async (dispatch, getState) => {
  dispatch({
    type: SELECT_DATASET,
    payload: name
  })
  dispatch({
    type: SELECT_LABEL,
    payload: null
  })
}

export const loadMetadata = (name) => async (dispatch, getState) => {
  if (!getState().explore.metadata[name]) {
    const { data } = await api.metadata(name)
    dispatch({
      type: LOAD_METADATA,
      payload: {
        dataset: name,
        metadata: data
      }
    })
  }
}

export const selectLabel = (name) => async (dispatch, getState) => {
  dispatch({
    type: SELECT_LABEL,
    payload: name
  })
}

const INITIAL_PAGES = 3
const PER_PAGE = 10

export const loadNext = () => async (dispatch, getState) => {
  const state = getState()
  const dataset = state.explore.dataset
  const label = state.explore.label
  let page
  let start
  if (dataset === state.explore.loadedDataset && label === state.explore.loadedLabel) {
    page = Math.ceil(state.explore.data.length / PER_PAGE) + 1
    start = state.explore.data.length
  } else {
    page = 1
    start = 0
  }

  const limit = (page === 1 ? INITIAL_PAGES : 1) * PER_PAGE

  try {
    const { data } = await api.data(dataset, label, page, limit)
    dispatch({
      type: LOAD_DATA,
      payload: {
        dataset: dataset,
        label: label,
        start: start,
        data: data,
      }
    })
  } catch (err) {
    if (err.response.status === 404) {
      dispatch({
        type: LOAD_DATA,
        payload: {
          dataset: dataset,
          label: label,
          start: start,
          data: []
        }
      })
    } else {
      throw err
    }
  }
}
