import React from 'react'
import { Link } from 'react-router-dom'

import styles from './Header.module.css'

const Header = () => {
  return (
    <nav class={`navbar navbar-expand-lg navbar-dark ${styles.navbar}`}>
      <Link to="/" className="navbar-brand">Label Errors in ML Test Sets</Link>
      <button class={`navbar-toggler ${styles.customToggler}`} type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a href="https://github.com/cleanlab/cleanlab" className={`nav-link ${styles.link}`}>Find issues in your data</a>
          </li>
          <li class="nav-item">
            <a href="https://cleanlab.ai/" className={`nav-link ${styles.link}`}>Fix issues in your data</a>
          </li>
          <li class="nav-item">
            <a href="https://cleanlab.ai/blog/csa/" className={`nav-link ${styles.link}`}>More audits of famous datasets</a>
          </li>
          <li class="nav-item">
            <a href="https://github.com/cleanlab/label-errors" className={`nav-link ${styles.link}`}>GitHub</a>
          </li>
          <li class="nav-item">
            <Link to="/about" className={`nav-link ${styles.link}`}>About</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
