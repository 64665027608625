import axios from 'axios'

const BASE_URL = '/api'

const api = axios.create({
  baseURL: BASE_URL
})

export const metadata = (name) => api.get('/dataset', { params: { dataset: name } })

export const data = (dataset, label, page, limit) => api.get('/data', { params: {
  dataset,
  page,
  limit,
  extraFilters: JSON.stringify(label ? { given_label: label } : {})
} })
