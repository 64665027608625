import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import reduxThunk from 'redux-thunk'

import createRootReducer from './reducers'

export const history = createBrowserHistory()

const middleware = applyMiddleware(
  routerMiddleware(history),
  reduxThunk
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  createRootReducer(history),
  composeEnhancers(middleware)
)
