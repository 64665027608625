import React, { useState, useEffect } from 'react'
import axios from 'axios'

import styles from './Text.module.css'

const Text = ({ wrap, path }) => {
  const [text, setText] = useState('')

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(path)
      setText(data)
    })()
  }, [path])

  return (
    <div className={wrap ? styles.textWrapped : styles.textPre}>
      {text.replaceAll('<br />', '\n')}
    </div>
  )
}

export default Text
