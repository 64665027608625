import { useState, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useResizeObserver from '@react-hook/resize-observer'

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const useOverflow = (target) => {
  const [overflow, setOverflow] = useState(false)

  const updateOverflow = (node) => {
    if (node !== null) {
      setOverflow(node.getBoundingClientRect().height > node.parentElement.getBoundingClientRect().height)
    }
  }

  useLayoutEffect(() => {
    updateOverflow(target.current)
  }, [target])

  useResizeObserver(target, (entry) => updateOverflow(entry.target))
  return overflow
}
