import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import explore from './explore'

const createRootReducer = (history) => combineReducers({
  explore,
  router: connectRouter(history)
})

export default createRootReducer
