import React, { useState, useRef } from 'react'
import Collapse from '@kunukn/react-collapse'

import Text from './Text'
import { useOverflow } from '../../hooks'

import styles from './Card.module.css'

const getId = (dataset, path) => {
  switch (dataset) {
    case 'MNIST':
      return path.match(/mnist\/(\d+)\.png/)[1]
    case 'CIFAR-10':
      return path.match(/cifar10\/(\d+)\.png/)[1]
    case 'CIFAR-100':
      return path.match(/cifar100\/(\d+)\.png/)[1]
    case 'Caltech-256':
      return path.match(/caltech256\/(.+)\.jpg/)[1]
    case 'ImageNet':
      return path.match(/ILSVRC2012_val_(\d+)\.JPEG/)[1]
    case 'QuickDraw':
      return path.match(/quickdraw\/(\d+)\.png/)[1]
    case '20news':
      return path.match(/20news\/(\d+)\.txt/)[1]
    case 'IMDB':
      return path.match(/imdb\/test\/(.+)\.txt/)[1]
    case 'Amazon':
      return path.match(/amazon\/(\d+)\.txt/)[1]
    case 'AudioSet':
      return path.match(/http:\/\/youtu\.be\/([^?]+)\?/)[1]
    default:
      throw new Error(`invalid dataset ${dataset}`)
  }
}

const Card = ({ dataset, type, data }) => {
  let label
  let guessed
  let mturk
  if (dataset === 'AudioSet') {
    // multi-label
    label = JSON.parse(data.label).join(', ')
    guessed = JSON.parse(data.guessed_label).pred.join(', ')
    mturk = JSON.parse(data.guessed_label).mturk.join(', ')
  } else {
    label = data.label
    guessed = data.guessed_label
    mturk = mturkLabelText(data)
  }

  let [open, setOpen] = useState(false)
  const target = useRef(null)
  const overflow = useOverflow(target)

  const handleClickOpen = () => {
    setOpen(!open)
  }

  let body
  switch (type) {
    case 'image':
      body = (<img className={`card-img-top`} src={data.path} alt={`${dataset} ${data.id}`} />)
      break;
    case 'text':
      body = (
        <div className="card-header">
          <div className={styles.summary}>
            <Collapse
              isOpen={open} 
              className={styles.collapse}
              collapseHeight="150px"
            >
              <div ref={target}><Text wrap={dataset !== '20news'} path={data.path} /></div>
            </Collapse>
            {(overflow || open) ? (<button type="button" className={`btn btn-link ${styles.readMore}`} onClick={handleClickOpen}>{open ? '- Read less' : '+ Read more'}</button>) : <></>}
          </div>
        </div>
      )
      break
    case 'video':
      body = (
        <div className="card-header">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe title={data.path} className="embed-responsive-item" src={data.path.replace("http://", "https://").replace("youtu.be", "youtube.com/embed")}></iframe>
          </div>
        </div>
      )
      break
    default:
      body = ''
      break
  }

  return (
    <div className="col-auto mb-3">
      <div className={`card ${styles[`${type}Card`]}`}>
        {body}
        <div className="card-body">
          <p className={`card-text ${styles.givenLabel}`}>{dataset} given label: <br /><span className={styles.givenLabelLabel}><b>{label}</b></span></p>
          <hr />
          <p className="card-text">Cleanlab guessed: <b>{guessed}</b></p>
          <p className="card-text">MTurk consensus: <b>{mturk}</b></p>
          <p className={`card-text ${styles.smallText}`}>ID: {getId(dataset, data.path)}</p>
        </div>
      </div>
    </div>
  )
}

const mturkLabelText = (data) => {
  switch (data.mturk_info) {
    case 'guessed':
      return data.guessed_label
    case 'neither':
      return `Neither ${data.label} nor ${data.guessed_label}`
    case 'both':
      return `Both ${data.label} and ${data.guessed_label}`
    case 'disagreement':
    case 'unavailable':
      return 'N/A, no agreement'
    default:
      return ''
  }
}

export default Card
