import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

import { loadNext } from '../../actions/explore'
import Card from './Card'

const DataDisplay = (props) => {
  const { loadNext } = props

  let data
  let hasMore
  // data is only available if loaded data agrees with desired data
  if (props.dataset === props.loadedDataset && props.label === props.loadedLabel) {
    data = props.data
    hasMore = props.hasMore
  } else {
    data = []
    hasMore = true // let the scroll component trigger data loading
  }

  const next = () => {
    loadNext()
  }

  // do initial load, because the infinite scroll component apparently does not take care of this
  useEffect(() => {
    if (data.length === 0 && hasMore) {
      loadNext()
    }
  }, [data, hasMore, loadNext])

  if (!props.metadata[props.dataset]) {
    data = [] // wait until metadata loaded
  }

  const items = data.map((data) => {
    return (
      <Card
        key={data.id} 
        dataset={props.dataset} 
        type={props.metadata[props.dataset].type} 
        data={data}
      />
    )
  })

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={next}
      hasMore={hasMore}
      scrollThreshold="200px"
      className="row d-flex justify-content-center"
    >
      {items}
    </InfiniteScroll>
  )
}

const mapStateToProps = (state) => {
  return state.explore
}

const mapDispatchToProps = { loadNext }

export default connect(mapStateToProps, mapDispatchToProps)(DataDisplay)
