import { SELECT_DATASET, SELECT_LABEL, LOAD_METADATA, LOAD_DATA } from '../actions/types'

const defaultState = {
  dataset: 'ImageNet',
  label: null, // null means all classes
  metadata: {},
  loadedDataset: null,
  loadedLabel: null, // null means all classes
  data: [],
  hasMore: true, // do we know if we have reached the end?
}

const explore = (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_DATASET:
      return {...state, dataset: action.payload}
    case SELECT_LABEL:
      return {...state, label: action.payload}
    case LOAD_METADATA: {
      const { dataset, metadata } = action.payload
      return {...state, metadata: {...state.metadata, [dataset]: metadata}}
    }
    case LOAD_DATA: {
      // check if response is still pertinent
      const { dataset, label, start, data } = action.payload
      if (dataset !== state.dataset || label !== state.label) {
        return state
      }
      let newData
      if (start === 0) {
        newData = data
      } else if (start === state.data.length) {
        newData = state.data.concat(data)
      } else {
        // stale, ignore
        newData = state.data
      }
      // add or splice in data
      return {...state,
        loadedDataset: dataset,
        loadedLabel: label,
        data: newData,
        hasMore: (data.length !== 0),
      }
    }
    default:
      return state
  }
}

export default explore
