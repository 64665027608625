import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Header from './Header'
import Explore from './Explore'
import About from './About'
import './App.css'

const App = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <Switch>
          <Route exact path="/" component={Explore} />
          <Route exact path="/about" component={About} />
        </Switch>
      </div>
    </>
  )
}

export default App
