import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useQuery } from '../../hooks'
import Page from '../Page'
import LabelList from './LabelList'
import DataDisplay from './DataDisplay'
import { selectDataset, selectLabel, loadMetadata } from '../../actions/explore'
import styles from './index.module.css'

const DATASET_NAMES = [
  'MNIST',
  'CIFAR-10',
  'CIFAR-100',
  'Caltech-256',
  'ImageNet',
  'QuickDraw',
  '20news',
  'IMDB',
  'Amazon',
  'AudioSet'
]

const ALL_CLASSES_LABEL = 'All classes with noise'

const Explore = (props) => {
  const { dataset, label, metadata, selectDataset, selectLabel, loadMetadata } = props

  const query = useQuery()
  const queryDataset = query.get('dataset')
  const queryLabel = query.get('label')
  const history = useHistory()
  useEffect(() => {
    if (queryDataset) {
      selectDataset(queryDataset)
      if (queryLabel) {
        selectLabel(queryLabel)
      }
      history.replace('/')
    }
  }, [queryDataset, queryLabel, selectDataset, selectLabel, history])

  // fix bad dataset/label names
  useEffect(() => {
    if (DATASET_NAMES.indexOf(dataset) === -1) {
      selectDataset('ImageNet')
    }
    if (label && metadata[dataset] && metadata[dataset].classes.indexOf(label) === -1) {
      selectLabel(null)
    }
  }, [dataset, label, metadata, selectDataset, selectLabel])

  useEffect(() => {
    loadMetadata(dataset)
  }, [dataset, loadMetadata])

  const onDatasetSelect = (event) => {
    selectDataset(event.target.value)
  }

  const datasetSelect = (
    <div className="form-group">
      <label htmlFor="dataset-select">Dataset:</label>
      <select id="dataset-select" className={`custom-select ml-2 mr-2 ${styles.select}`} onChange={onDatasetSelect} value={dataset}>
        {DATASET_NAMES.map((name) => {
          return (
            <option key={name} value={name}>{name}</option>
          )
        })}
      </select>
    </div>
  )

  const onLabelSelect = (event) => {
    const name = event.target.value
    selectLabel(name === ALL_CLASSES_LABEL ? null : name)
  }

  const labelSelect = (
    <div className="form-group">
      <label htmlFor="label-select">Label:</label>
      <select id="label-select" className={`custom-select ml-2 mr-2 ${styles.select}`} onChange={onLabelSelect} value={label || ALL_CLASSES_LABEL}>
        <option key={ALL_CLASSES_LABEL} value={ALL_CLASSES_LABEL}>{ALL_CLASSES_LABEL}</option>
        {(metadata[dataset] ? metadata[dataset].classes : []).map((name) => {
          return (
            <option key={name} value={name}>{name}</option>
          )
        })}
      </select>
    </div>
  )

  return (
    <Page title="Label Errors: Explore">
      <div className="row">
        <div className={`col-md-3 ${styles.labelList}`}>
          <LabelList />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-sm-12">
              <form className={`form-inline ${styles.form}`}>
                {datasetSelect}
                {labelSelect}
              </form>
            </div>
          </div>
          <div className={`${styles.galleryContainer} row`}>
            <div className="col-sm-12">
              <DataDisplay />
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

const mapStateToProps = (state) => {
  return {
    dataset: state.explore.dataset,
    label: state.explore.label,
    metadata: state.explore.metadata
  }
}

const mapDispatchToProps = { selectDataset, selectLabel, loadMetadata }

export default connect(mapStateToProps, mapDispatchToProps)(Explore)
